<template lang="">
    <div>
        <div class="headers">
            <span @click="previousPage"></span>
            <span>注销车辆证件上传</span>
            <span @click="LogOut()">退出</span>
       </div>
       <div class="LicensePlate" v-if="this.Type == '1'">
           <p>将拍照上传的车辆牌照号：</p>
           <p>{{LicensePlate}}</p>
       </div>
          <div class="LicensePlate" v-if="this.Type == '2'">
           <p>将拍照上传的车辆的进场编号：</p>
           <p>{{LicensePlate}}</p>
       </div>
	   <div class="hint">
		   <p>请选中个人还是企业</p>
		   <van-radio-group v-model="radio" class="group_"  direction="horizontal">
				<van-radio :name="0">车主信息</van-radio>
				<van-radio :name="1">企业信息</van-radio>
			</van-radio-group>
	   </div>
       <div class="List">
          
           <div class="cardImg">
               <div class="cardImg_box">
                    <van-uploader v-model="fileList_a"   :max-count="1" :after-read="afterRead_a" :before-delete="beforeDelete_a"/>
               </div>
               <p class="cardImg_text">行驶证正页</p>
           </div>
           <div class="cardImg">
               <div class="cardImg_box">
                    <van-uploader v-model="fileList_b"   :max-count="1" :after-read="afterRead_b" :before-delete="beforeDelete_b"/>
               </div>
               <p class="cardImg_text">行驶证副页</p>
           </div>
		    <div class="cardImg">
               <div class="cardImg_box">
                    <van-uploader v-model="fileList_h"   :max-count="1" :after-read="afterRead_h" :before-delete="beforeDelete_h"/>
               </div>
               <p class="cardImg_text">登记证</p>
           </div>
          
           <!-- <div class="cardImg">
               <div class="cardImg_box">
                    <van-uploader v-model="fileList_d"   :max-count="1" :after-read="afterRead_d" :before-delete="beforeDelete_d"/>
               </div>
               <p class="cardImg_text">车主身份证-背面</p>
           </div>
           <div class="cardImg">
               <div class="cardImg_box">
                    <van-uploader v-model="fileList_e"   :max-count="1" :after-read="afterRead_e" :before-delete="beforeDelete_e"/>
               </div>
               <p class="cardImg_text">代理人身份证-正面</p>
           </div> -->
		    <div class="cardImg" v-if="radio == 0">
               <div class="cardImg_box">
                    <van-uploader v-model="fileList_c"   :max-count="1" :after-read="afterRead_c" :before-delete="beforeDelete_c"/>
               </div>
               <p class="cardImg_text">车主身份证-正面</p>
           </div>
		   <br>
		   <div class="cardImg" v-if="radio == 0">
               <div class="cardImg_box">
                    <van-uploader v-model="fileList_d"   :max-count="1" :after-read="afterRead_d" :before-delete="beforeDelete_d"/>
               </div>
               <p class="cardImg_text">车主身份证-背面</p>
           </div>
           <!-- <div class="cardImg" v-if="radio == 0">
               <div class="cardImg_box">
                    <van-uploader v-model="fileList_f"   :max-count="1" :after-read="afterRead_f" :before-delete="beforeDelete_f"/>
               </div>
               <p class="cardImg_text">车主身份证-背面</p>
           </div> -->
           <div class="cardImg" v-if="radio == 1">
               <div class="cardImg_box" >
                    <van-uploader v-model="fileList_g"   :max-count="1" :after-read="afterRead_g" :before-delete="beforeDelete_g"/>
               </div>
               <p class="cardImg_text">企业证照</p>
           </div>
          
                  <!-- <div class="cardImg">
               <div class="cardImg_box">
                    <van-uploader v-model="fileList" multiple  :after-read="afterRead"/>
               </div>
               <p class="cardImg_text">车辆照片</p> -->
           <!-- </div> -->
            <div class="carImgVideo">
                <div >
					<video class="video_box" v-for="(item,index) in fileList_vidow" :key="index" :src="item.url" controls="controls"></video>
						<!-- <div v-for="(item,index) in fileList_img" :key="index" :src="item.url">
							<img v-if="item.url"
							class="img"
							:src="item.url"
							alt="预览图片"
							@click="getImg(listData, index)"
						/>
					</div> -->
           <van-uploader v-model="fileList" multiple   :max-count="8" :after-read="afterRead" accept="image/*" id="imgBox" :before-delete="beforeDelete_i"/>
                    <!-- <van-uploader v-model="fileList_img" multiple   :max-count="8" :after-read="afterRead" accept="image/*" id="imgBox" :before-delete="beforeDelete_i"/> -->
					
				
                    <p class="cardImg_text" v-if="imgStatus == 1">车辆影像</p>
                </div>
            </div>
          
                             

          
               
       


    
            <!-- <video id="videoId" controls width="100%"></video>
            <input id="pop_video" type="file" accept="video/*" capture="camcorder" v-on:change="getVideo(event, '2')" name="fileTrans" ref="file" value="">
            <div class="inputVideo">上传视频</div> -->
            <!-- <div class="forPreview_video" v-for="(item, index) in uploadVideoList" :key="index">
          <video :src="videoSrc"></video>
          <van-icon name="delete" @click="delBtn(index)" class="delte"/>
        </div>
        <van-uploader v-show="uploadVideoList.length < 2" preview-size="80px" accept="video/*" :before-read="beforeRead" :after-read="afterRead"></van-uploader> -->
         
           
        
          
       </div>
       <div class="footer">
            <button class="btn" @click="Submit()">提交</button>
       </div>
       
    </div>
</template>
<script>
import {deleteCookie } from '../../utils/env'

export default {
  components: {
    // [ImagePreview.Component.name]: ImagePreview.Component,
  },

  data() {
    return {
      LicensePlate: this.$route.query.id,
      arr: [1, 2, 3, 4, 5, 6, 7, 8, 9],
      file: "",
      fileList: [],
      videoSrc: "",
      fileList_a: [],
      fileList_b: [],
      fileList_c: [],
      fileList_d: [],
      fileList_e: [],
      fileList_f: [],
      fileList_g: [],
      fileList_h: [],
      fileList_i: [],
      ///////////////
	  radio:0,
      licensePlate: "", //行驶证正页
      passSub_page: "", //行驶证副页
      identification_front: "", //车主身份证-正面
      identification_back: "", //身份证-背面
      agentCardFront: "", //代理人身份证-正面
      agentCardBack: "", //代理人身份证-背面
      vehicleImages: "", //车辆影像 ,拼接
      enterpriseLicense: "", //企业证照
      registratioCard: "", //登记证

      uploadImgBox: [],

      fileList_vidow: [],
      fileList_img: [],

      imgStatus: 1,
    };
  },
  created() {
    // // //console.log(this.GLOBAL.BASE_URL)
    this.Type = this.$route.query.Type
    this.$http
      .post("/index.php/index/Car_Information/_get", {
        id: this.$route.query.ids,
      })
      .then((res) => {
        if(res.data.code == 0){
			this.radio = res.data.data.type
            //  let http = "http://192.168.1.50:8000/";
        let http = this.GLOBAL.BASE_URL;
        // //console.log(res.data.data.licensePlate.length);
        if (res.data.data.licensePlate.length > 0 ) {
            // alert("1"+res.data.data.licensePlate)
            // //console.log(res.data.data.licensePlate)
            //  //console.log(res.data.data.licensePlate.replace("_1",""))
            
            this.fileList_a = [
                {
                url: http + res.data.data.licensePlate.replace("_1",""),
                },
            ];
            this.licensePlate = res.data.data.licensePlate
        }
        if (res.data.data.passSub_page.length > 0) {
          this.fileList_b = [
            {
              url: http + res.data.data.passSub_page.replace("_1",""),
            },
          ];
          this.passSub_page = res.data.data.passSub_page
        }
        if (res.data.data.identification_front.length > 0) {
          this.fileList_c = [
            {
              url: http + res.data.data.identification_front.replace("_1",""),
            },
          ];
          this.identification_front = res.data.data.identification_front
        }
        if (res.data.data.identification_back.length > 0) {
          this.fileList_d = [
            {
              url: http + res.data.data.identification_back.replace("_1",""),
            },
          ];
          this.identification_back = res.data.data.identification_back
        }

        // if (res.data.data.agentCardFront.length > 0) {
        //   this.fileList_e = [
        //     {
        //       url: http + res.data.data.agentCardFront.replace("_1",""),
        //     },
        //   ];
        //   this.agentCardFront = res.data.data.agentCardFront
        //   //console.log( http + res.data.data.agentCardFront.replace("_1",""),)
        // }
        // if (res.data.data.agentCardBack.length > 0) {
        //   this.fileList_f = [
        //     {
        //       url: http + res.data.data.agentCardBack.replace("_1",""),
        //     },
        //   ];
        //   this.agentCardBack = res.data.data.agentCardBack
        // }
        if (res.data.data.enterpriseLicense.length > 0) {
          this.fileList_g = [
            {
              url: http + res.data.data.enterpriseLicense.replace("_1",""),
            },
          ];
          this.enterpriseLicense = res.data.data.enterpriseLicense
        }
        if (res.data.data.registratioCard.length > 0) {
          this.fileList_h = [
            {
              url: http + res.data.data.registratioCard.replace("_1",""),
            },
          ];
          this.registratioCard = res.data.data.registratioCard
        }

		    let arr = res.data.data.vehicleImages.split(',')
         if (arr.length > 0) {
        //        this.fileList = [
        //     {
        //       url: http + res.data.data.vehicleImages.replace("_1",""),
        //     },
        //   ];
        //   this.vehicleImages = res.data.data.vehicleImages
            // //  //console.log(res.data.data.vehicleImages.split(","))
          
           
            let obj = {}
            let arr1 = []
            arr.forEach(element => {
              
                 if(element != ''){
                    //console.log(element.split(".")) 
                    if(element.split(".")[1] == 'mp4'){
                      	obj={url: element}
					        	    this.fileList_vidow.push(obj)
                    }else{
                      obj={url: element}
                      this.fileList.push(obj)
                    }
                  
                   
                 }
                
             });
            // //console.log(this.fileList)
            this.vehicleImages = res.data.data.vehicleImages
            // //console.log(this.vehicleImages)
            // this.uploadImgBox = res.data.data.vehicleImages
        }
        //console.log(this.vehicleImages)
        }else if(res.data.code == '-1'){
          this.$toast.fail(res.data.msg)
          this.$router.push('/login')
            deleteCookie('token')

              // window.location.href="https://wechat.chexd.com/chaichejian/BeDefeated.html"
              //  this.$http.post('/index.php/index/wechat.wechat/domain').then(res=>{
                    
              //       if(res.data.code == 0){
              //           let domain = this.zhuan(res.data.data)
              //           let url = window.location.origin
                
              //           window.location.href="https://wechat.chexd.com/chaichejian/BeDefeated.html?domain=" +domain + '&url=' +url
              //       }
              //   })

        }else{
          this.$toast.fail(res.data.msg)

        }
       
      });
      //影像
  },
  methods: {
      zhuan(url){
                        let str = url
                        let str1 = str.replace('https','')
                        str1 = str1.replace('http','')
                        let str2 = 'https'+ str1
                        return str2
                    },
	  getImg(){

	  },
    //退出登录
      LogOut(){
      this.$http.post('/index.php/index/login_Out').then(res=>{
        
      
			this.$toast.success(res.data.msg)
          deleteCookie('token')
          // window.location.href="https://wechat.chexd.com/chaichejian/BeDefeated.html"
          this.$router.push('/')
          //  this.$http.post('/index.php/index/wechat.wechat/domain').then(res=>{
                    
          //           if(res.data.code == 0){
          //               let domain = this.zhuan(res.data.data)
          //               let url = window.location.origin
                
          //               window.location.href="https://wechat.chexd.com/chaichejian/BeDefeated.html?domain=" +domain + '&url=' +url
          //           }
          //       })
       
      })
    },
      beforeDelete_a(){
        //   //console.log(res)
          this.licensePlate = "";
            this.fileList_a = []
      },
       beforeDelete_b(){
          this.passSub_page = "";
          this.fileList_b = []
      },
       beforeDelete_c(){
          this.identification_front = "";
          this.fileList_c = []
      },
       beforeDelete_d(){
          this.identification_back = "";
          this.fileList_d = []
      },
       beforeDelete_e(){
          this.agentCardFront = "";
          this.fileList_e = []
      },
       beforeDelete_f(){
          this.agentCardBack = "";
          this.fileList_f = []
      },
       beforeDelete_g(){
          this.enterpriseLicense = "";
          this.fileList_g = []
      },
       beforeDelete_h(){
        //    alert("1")
          this.registratioCard = "";
          this.fileList_h = []
      },
       beforeDelete_i(file){
            this.fileList.forEach(element => {
                if(element ==  file ){
                    this.fileList.splice(this.fileList.indexOf(element), 1)
                }

            });

           //console.log( this.fileList)
           let arrs = []
           this.fileList.forEach(element =>{
               let url = '/'+element.url.split("//")[2]
               arrs.push(url)
           })
           //console.log(arrs.join(","))
           this.vehicleImages = arrs.join(",")
         
        
      },

   
    delImg(){

    },
    afterRead(file) {
    //   if (this.imgStatus == 1) {
    //       var Box = document.getElementsByClassName("van-uploader");
    //       var p = document.createElement("p"); //创建p节点
    //       p.innerHTML = "车辆影像";
    //       Box[8].appendChild(p); //往div中添加p节点
    //       this.imgStatus = 2;
    //   }

      let params = new FormData(); //创建form对象
      params.append("file", file.file);
      params.append("w", 200);
      params.append("h", 200);
      this.$http.post("/index.php/index/upload", params).then((res) => {
        if (res.data.code == 0) {
            if(this.vehicleImages.split(',').length > 0){
                this.uploadImgBox =  this.vehicleImages.split(',')
                this.uploadImgBox.push(res.data.data);
                
            }else{
                this.uploadImgBox.push(res.data.data);
            }
        //   this.uploadImgBox.push(this.vehicleImages.split(','))

          let arr = this.uploadImgBox;
        //   this.vehicleImages
     
          //console.log(arr)
        //   arr.push(this.vehicleImages)
        
          //console.log(arr.join(","));
          this.vehicleImages = arr.join(",");
        }
      });

      // //console.log(this.uploadImgBox)
    },

    //行驶证正页
    afterRead_a(file) {
      // //console.log(file)
      // 此时可以自行将文件上传至服务器
      let params = new FormData(); //创建form对象
      params.append("file", file.file);
      params.append("w", 200);
      params.append("h", 360);
      this.$http.post("/index.php/index/upload", params).then((res) => {
        if (res.data.code == 0) {
          //console.log(res.data.data);
          this.licensePlate = res.data.data;
        }
      });
    },
    //行驶证副页
    afterRead_b(file) {
      // //console.log(file)
      // 此时可以自行将文件上传至服务器
      let params = new FormData(); //创建form对象
      params.append("file", file.file);
      params.append("w", 200);
      params.append("h", 360);
      this.$http.post("/index.php/index/upload", params).then((res) => {
        if (res.data.code == 0) {
          //console.log(res.data.data);
          this.passSub_page = res.data.data;
        }
        // //console.log(this.uploadPicList)
      });
    },
    //车主身份证-正面
    afterRead_c(file) {
      // //console.log(file)
      // 此时可以自行将文件上传至服务器
      let params = new FormData(); //创建form对象
      params.append("file", file.file);
      params.append("w", 400);
      params.append("h", 300);
      this.$http.post("/index.php/index/upload", params).then((res) => {
        if (res.data.code == 0) {
          //console.log(res.data.data);
          this.identification_front = res.data.data;
        }
        // //console.log(this.uploadPicList)
      });
    },
    //身份证-背面
    afterRead_d(file) {
      // //console.log(file)
      // 此时可以自行将文件上传至服务器
      let params = new FormData(); //创建form对象
      params.append("file", file.file);
      params.append("w", 400);
      params.append("h", 300);
      this.$http.post("/index.php/index/upload", params).then((res) => {
        if (res.data.code == 0) {
          //console.log(res.data.data);
          this.identification_back = res.data.data;
        }
        // //console.log(this.uploadPicList)
      });
    },
    //代理人身份证-正面
    afterRead_e(file) {
      // //console.log(file)
      // 此时可以自行将文件上传至服务器
      let params = new FormData(); //创建form对象
      params.append("file", file.file);
      params.append("w", 400);
      params.append("h", 300);
      this.$http.post("/index.php/index/upload", params).then((res) => {
        if (res.data.code == 0) {
          //console.log(res.data.data);
          this.agentCardFront = res.data.data;
        }
        // //console.log(this.uploadPicList)
      });
    },
    //代理人身份证-背面
    afterRead_f(file) {
      // //console.log(file)
      // 此时可以自行将文件上传至服务器
      let params = new FormData(); //创建form对象
      params.append("file", file.file);
      params.append("w", 400);
      params.append("h", 300);
      this.$http.post("/index.php/index/upload", params).then((res) => {
        if (res.data.code == 0) {
          //console.log(res.data.data);
          this.agentCardBack = res.data.data;
        }
        // //console.log(this.uploadPicList)
      });
    },
    //企业证照
    afterRead_g(file) {
      // //console.log(file)
      // 此时可以自行将文件上传至服务器
      let params = new FormData(); //创建form对象
      params.append("file", file.file);
      params.append("w", 400);
      params.append("h", 200);
      this.$http.post("/index.php/index/upload", params).then((res) => {
        if (res.data.code == 0) {
          //console.log(res.data.data);
          this.enterpriseLicense = res.data.data;
        }
        // //console.log(this.uploadPicList)
      });
    },
    //登记证
    afterRead_h(file) {
      // //console.log(file)
      // 此时可以自行将文件上传至服务器
      let params = new FormData(); //创建form对象
      params.append("file", file.file);
      params.append("w", 300);
      params.append("h", 480);
      this.$http.post("/index.php/index/upload", params).then((res) => {
        if (res.data.code == 0) {
          //console.log(res.data.data);
          this.registratioCard = res.data.data;
        }
        // //console.log(this.uploadPicList)
      });
    },
    Submit() {
		this.$http.post("/index.php/index/Car_Information/add", {
			type:this.radio,
			licensePlate: this.licensePlate, //行驶证正页
			passSub_page: this.passSub_page, //行驶证副页
			identification_front: this.identification_front, //车主身份证-正面
			identification_back: this.identification_back, //身份证-背面
			
			vehicleImages: this.vehicleImages, //车辆影像 ,拼接
			enterpriseLicense: this.enterpriseLicense, //企业证照
			registratioCard: this.registratioCard, //登记证
			end_of_lifeVehiclesId: this.$route.query.ids,
			})
			.then((res) => {
			if (res.data.code == 0) {
				this.$router.go(-1);
				this.$toast.success("提交成功");
			} else {
				this.$toast.fail(res.data.msg);
			}
			});
    },
    previousPage() {
      this.$router.go(-1);
    },
  },
  watch: {
    fileList_a: function () {
      //   //console.log(this.fileList_a);
    },
  },
};
</script>
<style lang="scss" scoped> 
.van-uploader__preview {
  margin-right: 0.6rem !important;
}
html {
  background-color: #f5f5f5;
  height: 100%;
}
.headers {
  padding: 0 20px;
  background-color: cadetblue;
  height: 1rem;
  line-height: 1rem;
  color: white;
  font-size: 0.4rem;
  display: flex;
  position: fixed;
  top: 0;
  width: calc(100% - 40px);
  z-index: 9;
  justify-content: space-between;
  span {
    display: inline-block;
  }
  span:nth-child(1) {
    width: 30px;
    height: 30px;
    background-image: url(../../assets/u39.png);
    background-size: 100%;
    margin-top: 8px;
  }
}
.LicensePlate {
  width: 100%;
  height: 2.5rem;
  margin-top: 1.5rem;
  background-color: white;
  p:nth-child(1) {
    font-size: 14px;
    padding-left: 10px;
    padding-top: 10px;
  }
  p:nth-child(2) {
    font-size: 30px;
    text-align: center;
    margin-top: 1px;
  }
}
.List {
  // height: 9.4rem;
  background-color: white;
  margin-top: 0.5rem;
  font-size: 15px;
  display: flex;
  flex-wrap: wrap;
  .carImgVideo {
    width: 90%;
    //  height: 5rem;
    margin: 50px auto;
    //  background-color: red;
    p {
      text-align: center;
    }
    .cardImg_text {
      text-align: left !important;
    }
	#imgBox{
		float: left;
	}
	.video_box{
		width: 120px;
		height: 120px;
		float: left;
		}
		.img{
			width: 120px;
			height: 120px;
			float: left;
		}
  }

  .cardImg {
    // float: left;
    display: inline-block;
    width: 20%;
    height: 2rem;
    // border: 1px solid ;
    margin-left: 14px;
    margin-top: 0.7rem;
    .cardImg_box {
      position: relative;
      width: 1.5rem;
      height: 1.5rem;
      // border: 1px solid;
      margin: 0 auto;
      // img{
      //     position: absolute;
      //     // z-index: 99;
      // }
      // .van-uploader{
      //     position: absolute !important;
      //     left: 0px;
      // }
      // video
    }
    .cardImg_text {
      text-align: center;
    }
  }
}
.footer {
  height: 6rem;
  width: 100%;
  background-color: white;
  margin-top: 1px;
  font-size: 15px;
  padding-top: 10px;
  padding: left 10px;
  div {
    margin-top: 10px;
    margin-left: 10px;
  }
  .btn {
    display: block;
    width: 60%;
    height: 1rem;
    line-height: 1rem;
    text-align: center;
    color: white;
    font-size: 15px;
    margin: 30px auto;
    background-color: teal;
  }
  p {
    text-align: center;
    font-size: 16px;
  }
}
.hint{
	padding: 10px;
	p{
		font-size: 16px;
	}
	.group_{
		font-size: 16px;
	}
}

</style>
